<template>
  <v-content>
    <v-container fluid grid-list-lg style="margin-bottom: 58px;">
      <v-layout wrap>
        <v-flex xs12>
          <AppTitle :subtitle="movements.length > 0 ? `Movimientos del ${dateStart} al ${dateEnd}` : ''">
            <template slot="title"
              >Sistema de caja
            </template>
          </AppTitle>
        </v-flex>
        <v-flex xs12 sm3>
          <v-card>
            <TitleCard title="Cajas" subtitle="Filtre las cajas por usuario"></TitleCard>
            <v-divider light></v-divider>
            <v-card-text>
              <v-select
                v-model="cashSelected"
                :items="cashUser"
                item-text="name"
                :item-value="
                  e => {
                    return e
                  }
                "
                label="Cajas disponibles"
              ></v-select>
            </v-card-text>
          </v-card>
          <v-divider class="my-3"></v-divider>
          <v-card v-if="cashSelected.name !== undefined">
            <v-card-text class="text-xs-center">
              <v-avatar
                v-if="cashSelected.detail.photo !== undefined && cashSelected.detail.photo !== ''"
                :size="130"
                color="grey lighten-4 mb-3"
              >
                <img
                  :src="
                    `https://storage.googleapis.com/rosalinda-imagenes/foto-repartidor/${cashSelected.detail.photo}`
                  "
                  alt="avatar"
                />
              </v-avatar>
              <h3 :class="`${cashSelected.saldo > 0 ? 'success--text' : 'error--text'} title mb-1`">
                {{ cashSelected.saldo | toPrice }}
              </h3>
              <span class="grey--text text--lighten-1"> Saldo actual </span>
              <h1 class="headline mt-1">{{ cashSelected.name }}</h1>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm9>
          <v-card>
            <TitleCard
              title="Movimientos por usuario"
              subtitle="Filtre los movimientos por fecha o busque uno por glosa"
            >
              <template slot="button">
                <v-btn v-if="movements.length > 0" flat icon @click="exportExcel">
                  <v-icon>save_alt</v-icon>
                </v-btn>
              </template>
            </TitleCard>
            <v-divider light></v-divider>
            <v-card-text>
              <v-layout>
                <v-flex xs4>
                  <v-menu
                    ref="menuDateStart"
                    v-model="menuDateStart"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="dateStart"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="dateStart"
                      label="Inicio"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker v-model="dateStart" @input="$refs.menuDateStart.save(dateStart)"> </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs4>
                  <v-menu
                    ref="menuDateEnd"
                    v-model="menuDateEnd"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="dateEnd"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="dateEnd"
                      label="Fin"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                    <v-date-picker v-model="dateEnd" @input="$refs.menuDateEnd.save(dateEnd)"> </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    append-icon="search"
                    @keyup.native="searchMovement"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider light></v-divider>
            <v-data-table
              :headers="headers"
              :items="movements"
              class="elevation-0 order-table"
              color="secondary"
              :disable-initial-sort="true"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              rows-per-page-text="Movimientos por pagina"
              :loading="loading"
            >
              <template slot="no-data">
                No hay movimientos disponibles
              </template>
              <template slot="items" slot-scope="props">
                <tr>
                  <td class="text-xs-left">{{ props.item.user }}</td>
                  <td class="text-xs-left">{{ props.item.add }}</td>
                  <td class="text-xs-left">{{ props.item.glosa }}</td>
                  <td class="text-xs-right">
                    {{ props.item.value | toPrice }}
                  </td>
                  <td
                    :class="
                      `${props.item.total > 0 ? 'success--text' : 'error--text'}
                    text-xs-right`
                    "
                  >
                    {{ props.item.total | toPrice }}
                  </td>
                  <td>
                    <template v-if="props.item.edit">
                      <v-icon small class="mr-2" @click="editItem(props.item)">
                        edit
                      </v-icon>
                      <v-icon small @click="deleteMovementCash(props.item)">
                        delete
                      </v-icon>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
      <v-btn fab dark fixed bottom right color="success" style="right: 90px;" @click="openAddEntry(1)">
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn fab dark fixed bottom right color="error" @click="openAddEntry(0)">
        <v-icon>remove</v-icon>
      </v-btn>
      <v-dialog v-model="dialogEntry" width="500" persistent>
        <v-card>
          <TitleDialog
            :color="newMovement.type === 1 ? 'success' : 'error'"
            :title="
              `${newMovement.id === 0 ? 'Nueva' : 'Actualizar'}
            ${newMovement.type === 1 ? 'entrada' : 'salida'}`
            "
          >
            <template slot="icons">
              <v-btn icon @click="resetMovement">
                <v-icon>clear</v-icon>
              </v-btn>
            </template>
          </TitleDialog>

          <v-card-text class="body-1">
            <v-layout wrap>
              <v-flex xs12>
                <v-menu
                  ref="menuDateNew"
                  v-model="menuDateNew"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="newMovement.date"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="newMovement.date"
                    label="Fecha de movimiento"
                    prepend-icon="event"
                    color="secondary"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="newMovement.date" @input="$refs.menuDateNew.save(newMovement.date)">
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="newMovement.movements"
                  :items="movementsList"
                  item-text="text"
                  item-value="id"
                  color="secondary"
                  label="Movimiento"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="newMovement.document"
                  :items="documentList"
                  item-text="text"
                  item-value="id"
                  color="secondary"
                  label="Documento"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-textarea v-model="newMovement.text" multi-line color="secondary" :label="`Glosa`"> </v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="newMovement.value"
                  prepend-icon="attach_money"
                  color="secondary"
                  :label="`Monto`"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn flat color="back" @click="resetMovement">
              volver
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" flat @click="addMovementCash">
              <span v-if="newMovement.id === 0">Listo</span>
              <span v-else>Actualizar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import { GET_API, GET_CASH_USER, MOVEMENT_CASH } from '../config'
import AppTitle from './useful/title.vue'
import TitleCard from './useful/titleCard.vue'
import TitleDialog from './useful/titleDialog.vue'

export default {
  name: 'CashDesk',
  components: { AppTitle, TitleCard, TitleDialog },
  metaInfo: {
    title: 'Cajas'
  },
  data() {
    return {
      search: null,
      fab: false,
      menuDateStart: false,
      loading: false,
      menuDateEnd: false,
      menuDateNew: false,
      dialogEntry: false,
      dateStart: '',
      dateEnd: '',
      cashUser: [],
      movements: [],
      movementsAll: [],
      headers: [
        { text: 'Ingresado por:', align: 'left', sortable: false },
        { text: 'Fecha', align: 'left', sortable: false },
        { text: 'Glosa', align: 'left', sortable: false },
        { text: 'Monto', align: 'right', sortable: false },
        { text: 'Saldo', align: 'right', sortable: false },
        { text: '', sortable: false }
      ],
      movementsOut: [
        { id: 'peaje', text: 'Peaje o estacionamiento' },
        { id: 'combustible', text: 'Combustible' },
        { id: 'colacion', text: 'Colacion / Almuerzo' },
        { id: 'gastos generales', text: 'Gastos generales' },
        { id: 'insumos varios', text: 'Insumos varios' },
        { id: 'insumos flores', text: 'Insumos flores' }
      ],
      movementsIn: [
        { id: 'Ingreso por venta', text: 'Ingreso por venta' },
        { id: 'Aporte caja chica', text: 'Aporte caja chica' }
      ],
      movementsList: [],
      documentList: [
        { id: 'boleta', text: 'Boleta' },
        { id: 'factura', text: 'Factura' },
        { id: 'comprobante', text: 'Comprobante' },
        { id: 'sin documento', text: 'Sin documento' }
      ],
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      cashSelected: {},
      newMovement: {
        date: null,
        text: null,
        document: null,
        value: null,
        movements: 'peaje',
        type: 0,
        id: 0
      },
      pagination: { rowsPerPage: 50 }
    }
  },
  computed: {
    height() {
      return `${window.innerHeight - 80}px`
    }
  },
  watch: {
    cashSelected() {
      this.getMovementsCash()
    },
    dateStart() {
      this.getMovementsCash()
    },
    dateEnd() {
      this.getMovementsCash()
    }
  },
  mounted() {
    this.getCashUser()
    this.dateStart = this.$moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
    this.dateEnd = this.$moment().format('YYYY-MM-DD')
    this.newMovement.date = this.$moment().format('YYYY-MM-DD')
  },
  methods: {
    async getCashUser() {
      try {
        const res = await this.$http.get(GET_CASH_USER)
        this.cashUser = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getMovementsCash() {
      try {
        if (this.cashSelected.id) {
          const res = await this.$http.get(`${MOVEMENT_CASH}/${this.cashSelected.id}`, {
            params: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            }
          })
          this.movementsAll = res.data
          this.movements = res.data
          this.$store.dispatch('setConfirm', { active: false })
          this.newMovement = {
            date: this.$moment().format('YYYY-MM-DD'),
            text: null,
            document: null,
            value: null,
            movements: null,
            type: 0,
            id: 0
          }
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async addMovementCash() {
      try {
        const res = await this.$http.post(`${MOVEMENT_CASH}/${this.cashSelected.id}`, {
          item: this.newMovement
        })
        if (!res.data.error) {
          this.getMovementsCash()
          this.resetMovement()
          this.changeSaldo(this.cashSelected, res.data.saldo)
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteMovementCashAction() {
      try {
        const res = await this.$http.delete(`${MOVEMENT_CASH}/${this.cashSelected.id}`, {
          params: {
            item: this.newMovement
          }
        })
        if (!res.data.error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: res.data.msj
          })
          this.getMovementsCash()
          this.changeSaldo(this.cashSelected, res.data.saldo)
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    deleteMovementCash(item) {
      this.newMovement = {
        id: item.id,
        date: item.add,
        text: item.glosa,
        document: item.document,
        value: item.value,
        movements: item.movement,
        type: item.value > 0 ? 1 : 0
      }
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar movimiento?',
        description: '',
        action: this.deleteMovementCashAction
      })
    },
    searchMovement() {
      const { search } = this
      const result = this._.filter(this.movementsAll, item => {
        const res = this._.includes(this._.lowerCase(item.glosa), this._.lowerCase(search))
        return res
      })
      this.movements = result
    },
    exportExcel() {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: 'Exportar datos a excel',
        description: '',
        action: this.exportExcelAction
      })
    },
    exportExcelAction() {
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'exportExcelMovementsCash',
          cashSelected: this.cashSelected,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd
        }
      }).then(res => {
        this.$store.dispatch('setConfirm', {
          active: false,
          title: '',
          description: '',
          action: {}
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Excel generado'
        })
        const { url } = res.data.json
        window.location.href = url
      })
    },
    openAddEntry(type) {
      this.dialogEntry = true
      this.newMovement.type = type
      this.newMovement.movements = null
      this.movementsList = this.newMovement.type === 0 ? this.movementsOut : this.movementsIn
    },
    editItem(item) {
      this.openAddEntry(item.value > 0 ? 1 : 0)
      this.newMovement = {
        id: item.id,
        date: item.add,
        text: item.glosa,
        document: item.document,
        value: item.value,
        movements: item.movement,
        type: item.value > 0 ? 1 : 0
      }
    },
    changeSaldo(cashSelected, saldo) {
      this.cashSelected.saldo = saldo
      const index = this._.findIndex(this.cashUser, ['id', cashSelected.id])
      this.cashUser[index].saldo = saldo
    },
    resetMovement() {
      this.newMovement = {
        date: this.$moment().format('YYYY-MM-DD'),
        text: null,
        document: null,
        value: null,
        movements: 'peaje',
        type: 0,
        id: 0
      }
      this.dialogEntry = false
    }
  }
}
</script>

<style></style>
